export const IconButtonSize = {
  XS: "xs",
  SM: "sm",
  MD: "md"
};

export const IconButtonColor = {
  WHITE: "white",
  LIGHT: "light",
  TRANSPARENT: "transparent",
  PRIMARY: "primary",
  RED: "red",
  SECONDARY: "secondary"
};
